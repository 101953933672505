import type { MarketBullet } from '@deorderbook/sdk/subgraph-api'
import type { BasicTokenType } from '@base/types/tokens'

// bazaar
/** @deprecated should import type from shared */
export const BULLET_TYPE = ['sellTokenBULLET', 'uBULLET']
/** @deprecated should import type from shared */
export type BulletType = 'sellTokenBULLET' | 'uBULLET'
/** @deprecated should import enum from shared */
export enum BulletTypeEnum {
  sellTokenBULLET,
  uBULLET,
}

export const BULLET_STATUS = ['In Sale', 'Not in Sale', 'OTC Expired'] as const
export type BulletStatus = 'In Sale' | 'Not in Sale' | 'OTC Expired'
export enum BulletStatusEnum {
  IN_SALE,
  NOT_IN_SALE,
  OTC_EXPIRED,
}

export interface BestOffer {
  address: string
  bestOffer: string | undefined
  bestOfferToken: BasicTokenType
}
export interface FormattedMarketBullets extends MarketBullet {
  token: BulletType
  expiryTimestamp: string
  nickName: string | undefined
  recommend: string | undefined
  OTCPrice: string
  totalSellValue: string
}
export interface MyBullet {
  type: 'BULLET'
  token: BulletType
  status: BulletStatus
  nickName: string
  optionAddress: string
  bullet: string
  matchingBulletAmount: string
  strikePrice: string
  price: string
  exerciseTimestamp: string
  expiryTimestamp: string
  amount: string
  value: string
  recommend: ComputedRef<string> | Ref<string> | undefined
  recommendToken: BasicTokenType | undefined
  startTimestamp: string
  expired: boolean
}

export interface BulletForSale extends MyBullet {
  sellAmount: string
  OTCPrice: string
  totalSellValue: string
  OTCOpenTime: Date | string | number
  marketBulletId: string
  seller: string
  buyToken: string
}

export interface BulletTableRow {
  address: string
  token: string
  optionType: string
  strike: string
  expiry: string
  myShareRaw: string
  myShare: string
  myShareUSD: string
  /* Required if showTotal is true */
  totalRaw?: string
  total?: string
  totalUSD?: string
}
