import { Big } from 'big.js'
import type { BulletForSale, BulletStatus, MyBullet } from '@base/types/bullet'
import { BULLET_STATUS, BulletStatusEnum } from '@base/types/bullet'
import { HODL } from '@base/types/hodl'
import { OPTION_TYPE, type BulletType } from '@deorderbook/shared'

export type BulletsType = { sellTokenBULLET: MyBullet[]; uBULLET: MyBullet[] }
interface BulletFilterOption {
  activeOnly?: MaybeRefOrGetter<boolean>
  address?: MaybeRefOrGetter<string>
}
const ONE_DAY = 24 * 60 * 60

export const useBullet = (filterOption?: BulletFilterOption) => {
  const optionAccountsStore = useOptionAccountsStore()
  const bulletsStore = useBulletsStore()
  // TODO: should get all bullets, not UserBullets
  const {
    formattedUserBullets,
    loading: optAccStoreIsLoading,
    actionRefreshOptionAccounts,
  } = toRefs(optionAccountsStore)

  const {
    marketBullets: marketBulletsRaw,
    loading: bulletStoreIsLoading,
    actionRefreshBullets,
  } = toRefs(bulletsStore)

  const wallet = useWallet()
  const { getTokenPrice, divDecimals } = useTokens()

  const isLoading = computed(() => {
    return bulletStoreIsLoading.value || optAccStoreIsLoading.value
  })
  const { isConnected } = wallet
  const sellTokenBullets: Ref<MyBullet[]> = ref([])
  const uBullets: Ref<MyBullet[]> = ref([])
  const bullets: BulletsType = reactive({
    sellTokenBULLET: sellTokenBullets,
    uBULLET: uBullets,
  })

  // current user in sale bullets
  const sellTokenMarketBullets: Ref<BulletForSale[]> = ref([])
  const uMarketBullets: Ref<BulletForSale[]> = ref([])

  const marketBullets = reactive({
    sellTokenBULLET: sellTokenMarketBullets,
    uBULLET: uMarketBullets,
  })

  const formatMarketBullets = () => {
    const bMarketBulletsList: BulletForSale[] = []
    const uMarketBulletsList: BulletForSale[] = []
    marketBulletsRaw.value
      .filter((marketBullet) => {
        if (toValue(filterOption?.address)) {
          return marketBullet.seller === toValue(filterOption?.address)
        }
        return true
      })
      .forEach((marketBullet) => {
        const marketBulletOTCOpenTimestamp = useTimestamp(
          marketBullet.openTimestamp,
          {
            unit: 'milliseconds',
          },
        )
        const { uBulletsList, sellTokenBulletsList } = formatMyBullets()
        const bulletListEnum = {
          uBULLET: uBulletsList,
          sellTokenBULLET: sellTokenBulletsList,
        }
        const thisBulletInfo = bulletListEnum[
          (+marketBullet.optionType === OPTION_TYPE.SELL
            ? 'sellTokenBULLET'
            : 'uBULLET') as BulletType
        ].find((bullet) => bullet.bullet === marketBullet.bullet)

        if (thisBulletInfo) {
          const bulletForSale: BulletForSale = {
            ...thisBulletInfo,
            status: (+marketBulletOTCOpenTimestamp > Date.now()
              ? BULLET_STATUS[BulletStatusEnum.IN_SALE]
              : BULLET_STATUS[BulletStatusEnum.OTC_EXPIRED]) as BulletStatus,
            marketBulletId: marketBullet.markerBulletId,
            seller: marketBullet.seller,
            sellAmount: marketBullet.sellAmount,
            OTCPrice: Big(marketBullet.totalSellValue)
              .div(marketBullet.sellAmount)
              .toFixed(),
            totalSellValue: marketBullet.totalSellValue,
            OTCOpenTime: marketBullet.openTimestamp,
            buyToken: marketBullet.buyToken,
            recommend:
              getTokenPrice(thisBulletInfo.token, {
                exerciseTimestamp: thisBulletInfo.exerciseTimestamp,
                strikePrice: divStrikePrice(thisBulletInfo.strikePrice),
                address: thisBulletInfo.bullet,
              }) ?? undefined,
          }
          if (
            +marketBullet.optionType === OPTION_TYPE.SELL &&
            +marketBullet.sellAmount > 0
          ) {
            bMarketBulletsList.push(bulletForSale)
          }
          if (
            +marketBullet.optionType === OPTION_TYPE.BUY &&
            +marketBullet.sellAmount > 0
          ) {
            uMarketBulletsList.push(bulletForSale)
          }
        }
      })
    return {
      bMarketBulletsList,
      uMarketBulletsList,
    }
  }

  function formatMyBullets() {
    const sellTokenBulletsList: MyBullet[] = []
    const uBulletsList: MyBullet[] = []
    formattedUserBullets.value
      .filter((bullet) => {
        if (toValue(filterOption?.address)) {
          return bullet.owner === toValue(filterOption?.address)
        }
        return true
      })
      .sort((x, y) => {
        if (+x.exerciseTimestamp - +y.exerciseTimestamp) {
          return +x.exerciseTimestamp - +y.exerciseTimestamp
        } else {
          return +x.strikePrice - +y.strikePrice
        }
      })
      .forEach((bullet) => {
        const bulletExerciseTimestamp = useTimestamp(bullet.exerciseTimestamp, {
          unit: 'seconds',
        })
        const isNotExpired =
          +bulletExerciseTimestamp + ONE_DAY > Date.now() / 1000

        const myBulletFormatted: MyBullet = {
          type: 'BULLET',
          token: bullet.type,
          status: BULLET_STATUS[BulletStatusEnum.NOT_IN_SALE] as BulletStatus,
          nickName: bullet.name,
          optionAddress: bullet.address,
          bullet: bullet.bullet,
          matchingBulletAmount: bullet.amount,
          strikePrice: bullet.strikePrice,
          price: useUSDFormat(bullet.amountUSD),
          exerciseTimestamp: bullet.exerciseTimestamp,
          expiryTimestamp: (
            Number(bulletExerciseTimestamp) + ONE_DAY
          ).toString(),
          amount: bullet.amount,
          recommend:
            getTokenPrice(bullet.type, {
              exerciseTimestamp: bullet.exerciseTimestamp,
              strikePrice: divStrikePrice(bullet.strikePrice),
              address: bullet.bullet,
            }) ?? undefined,
          recommendToken: HODL.uHODL,
          value: useUSDFormat(
            Big(divDecimals(bullet.amount, 'bullet').value).times(
              bullet.amountUSD,
            ),
          ),
          startTimestamp: bullet.startTimestamp,
          expired: !isNotExpired,
        }
        if (+bullet.optionType === OPTION_TYPE.SELL) {
          if (toValue(filterOption?.activeOnly) && isNotExpired) {
            sellTokenBulletsList.push(myBulletFormatted)
          }
        }
        if (+bullet.optionType === OPTION_TYPE.BUY) {
          if (toValue(filterOption?.activeOnly) && isNotExpired) {
            uBulletsList.push(myBulletFormatted)
          }
        }
      })
    return {
      sellTokenBulletsList,
      uBulletsList,
    }
  }
  watch(
    [
      isConnected,
      () => formattedUserBullets.value.length,
      () => filterOption,
      optAccStoreIsLoading,
    ],
    (newValue, oldValue) => {
      if (
        isConnected.value &&
        !optAccStoreIsLoading.value &&
        !objectEqual(newValue, oldValue)
      ) {
        const { sellTokenBulletsList, uBulletsList } = formatMyBullets()

        sellTokenBullets.value = sellTokenBulletsList.filter(
          (x) => Number(x.amount) !== 0,
        )
        uBullets.value = uBulletsList.filter((x) => Number(x.amount) !== 0)
      }
    },
    {
      immediate: true,
      deep: true,
    },
  )

  watch(
    [
      isConnected,
      () => marketBulletsRaw.value.length,
      () => bullets.sellTokenBULLET.length,
      () => bullets.uBULLET.length,
      () => filterOption,
      bulletStoreIsLoading,
      optAccStoreIsLoading,
    ],
    (newValue, oldValue) => {
      if (
        isConnected.value &&
        !bulletStoreIsLoading.value &&
        !optAccStoreIsLoading.value &&
        !objectEqual(newValue, oldValue)
      ) {
        const { bMarketBulletsList, uMarketBulletsList } = formatMarketBullets()
        sellTokenMarketBullets.value = bMarketBulletsList
        uMarketBullets.value = uMarketBulletsList
      }
    },
    {
      immediate: true,
      deep: true,
    },
  )
  const refreshBullets = (type: 'all' | 'market' | 'my') => {
    if (['all', 'market'].includes(type)) {
      actionRefreshBullets.value(true)
    }
    if (['all', 'my'].includes(type)) {
      actionRefreshOptionAccounts.value(true)
    }
  }
  return {
    formattedUserBullets,
    bullets,
    marketBullets,
    refreshBullets,
    isLoading,
  }
}
